<template>
  <v-carousel height="100vh" hide-delimiter-background cycle>
    <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
      <div class="carousel-ovelary">
        <div class="carousel-text-wrapper">
          <div class="white--text text-h2 font-weight-bold text-center">
            {{ item.title }}
          </div>
          <div class="white--text text-h5 text-center mt-12">
            {{ item.text }}
          </div>
          <div class="text-center mt-12">
            <v-btn depressed rounded large color="primary" @click="$emit('clickOnBtn')">
              {{ item.actionText }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "PageCarousel",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-carousel {
  position: relative;
  height: 100vh;
}
.carousel-ovelary {
  background: rgba(0, 0, 0, 0.438);
  width: 100%;
  padding: 5rem;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.carousel-text-wrapper {
  max-width: 65rem;
  margin: auto;
}
</style>