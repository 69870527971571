import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    locale: '',
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },
  },
});

export default store;
