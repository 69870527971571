<template>
  <page-slide-group :items="items">
    <template #item="{ src }">
      <v-img :src="src"></v-img>
    </template>
  </page-slide-group>
</template>

<script>
import PageSlideGroup from "./PageSlideGroup.vue";
export default {
  components: { PageSlideGroup },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.fillPhotosArray(12);
  },
  methods: {
    fillPhotosArray(photosCount) {
      for (let i = 1; i <= photosCount; i++) {
        let item = {};
        this.items.push(item);
      }

      this.items.forEach((item, i) => {
        item.src = `https://vltavaparty.b-cdn.net/slider/${i+1}.jpg`
      });
    },
  },
};
</script>

<style>
</style>