<template>
  <v-footer class="grey darken-3 white--text pa-8" padless app absolute>
    <div class="content-wrapper">
      <v-row justify="center" no-gutters>
        <v-col class="mb-4" cols="12">
          <div class="text-h5 mb-4">
            {{ $t("footer.contactDetails") }}
          </div>
          <div class="mb-2">email: info@vltavaparty.cz,</div>
          <div>tel: +420 603 440 372</div>
        </v-col>
        <v-col class="text-right white--text" cols="12">
          {{ new Date().getFullYear() }} — <strong>Vltava party</strong>
        </v-col>
      </v-row>
    </div>
  </v-footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.v-footer {
  display: block;
}
.content-wrapper {
  max-width: 1200px;
  margin: auto;
}
</style>