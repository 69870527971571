import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';

import cs from './i18n/cs';
import en from './i18n/en';

Vue.config.productionTip = false;

Vue.use(VueI18n);

let locale = 'cs';
if (localStorage.getItem('locale')) {
  locale = localStorage.getItem('locale');
}

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'cs',
  silentFallbackWarn: true,
  messages: {
    cs: cs,
    en: en,
  },
});

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
