<template>
  <v-app-bar app color="transparent" elevate-on-scroll>
    <v-toolbar-title>Vltava Party</v-toolbar-title>

    <v-spacer></v-spacer>
    <v-btn
      rounded
      depressed
      color="primary"
      large
      class="mr-4"
      @click="clickOnBtn"
    >
      {{ $t("appBar.reserve") }}
    </v-btn>

    <v-menu open-on-hover bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar size="40" v-bind="attrs" v-on="on">
          <v-img :src="require(`../assets/imgs/${flagImg}`)" alt="czech-flag" />
        </v-avatar>
      </template>

      <v-list>
        <v-list-item-group v-model="selectedLanguage" color="primary">
          <v-list-item
            v-for="(language, index) in languages"
            :key="index"
            :value="language.value"
            @click="changeLangue(language.value)"
          >
            <v-list-item-icon>
              <v-img
                max-width="30"
                :src="require(`../assets/imgs/${language.imgName}`)"
                alt="czech-flag"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ language.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: "cs",
      languages: [
        {
          name: "Čeština",
          imgName: "cz-flag.png",
          value: "cs",
        },
        {
          name: "English",
          imgName: "en-flag.png",
          value: "en",
        },
      ],
    };
  },
  mounted() {
    if (this.localStorageLang) {
      this.selectedLanguage = this.localStorageLang;
    }
  },
  computed: {
    localStorageLang() {
      return localStorage.getItem("locale");
    },
    isCzechLang() {
      return this.selectedLanguage === "cs";
    },
    flagImg() {
      return this.isCzechLang ? "cz-flag.png" : "en-flag.png";
    },
  },
  methods: {
    clickOnBtn() {
      open(
        "https://vltavaparty.reenio.cz/cs/#/terms/2022-08-15;viewMode=7-days"
      );
    },
    changeLangue(value) {
      localStorage.setItem("locale", value);
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar {
  color: white;
  font-weight: bold;
}

.v-app-bar.v-app-bar--is-scrolled {
  background-color: white!important;
  color: black;
  font-weight: bold
}
</style>