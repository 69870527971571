<template>
  <div class="page-divider">
    <hr />
    <div class="d-flex dots-wrapper">
      <div v-for="n in 3" :key="n" class="dots"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page-divider {
  position: relative;
  max-width: 15rem;
  margin: 4rem auto;
}
hr {
  border: none;
  height: 1px;
  color: gainsboro; /* old IE */
  background-color: gainsboro; /* Modern Browsers */
}
.dots-wrapper {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 1rem;
  .dots {
    background: gainsboro;
    width: 0.4rem;
    height: 0.4rem;
    margin: 0.2rem;
    border-radius: 0.4rem;
  }
}
</style>