<template>
  <div class="page-price-table">
    <div class="content-wrapper">
      <h1 class="text-center">{{ $t("pagePriceTable.title") }}</h1>
      <div class="grey--text text-body-2 my-4 text-center font-italic">
        {{ $t("pagePriceTable.text") }}
      </div>
      <div class="d-flex justify-center">
        <div
          class="price-card ma-4 mt-12"
          :class="{ 'price-card__favorite': priceCard.favorite }"
          v-for="priceCard in priceCards"
          :key="priceCard.title"
        >
          <div v-if="priceCard.favorite" class="favorite-label">
            {{ $t("pagePriceTable.favorite") }}
          </div>
          <div class="price-card__title mb-4">{{ priceCard.title }}</div>
          <div class="price-card__price mb-4">{{ priceCard.price }}</div>

          <div class="price-card__text">{{ priceCard.text }}</div>
          <v-btn
            class="price-card__button"
            depressed
            rounded
            @click="clickOnBtn(priceCard.action)"
            >{{ priceCard.btnText }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      priceCards: [
        {
          favorite: false,
          title: this.$t("pagePriceTable.cards.dayTime.title"),
          price: this.$t("pagePriceTable.cards.dayTime.price"),
          text: this.$t("pagePriceTable.cards.dayTime.text"),
          btnText: this.$t("pagePriceTable.cards.dayTime.btnText"),
          action:
            "https://vltavaparty.reenio.cz/cs/#/service/odpoledni-pronajem-houseboatu-18078/2022-08-15;viewMode=7-days",
        },
        {
          favorite: true,
          title: this.$t("pagePriceTable.cards.allDay.title"),
          price: this.$t("pagePriceTable.cards.allDay.price"),
          text: this.$t("pagePriceTable.cards.allDay.text"),
          btnText: this.$t("pagePriceTable.cards.allDay.btnText"),
          action:
            "https://vltavaparty.reenio.cz/cs/#/service/celodenni-pronajem-houseboatu-18077/2022-08-15;viewMode=7-days",
        },
        {
          favorite: false,
          title: this.$t("pagePriceTable.cards.nightTime.title"),
          price: this.$t("pagePriceTable.cards.nightTime.price"),
          text: this.$t("pagePriceTable.cards.nightTime.text"),
          btnText: this.$t("pagePriceTable.cards.nightTime.btnText"),
          action:
            "https://vltavaparty.reenio.cz/cs/#/service/vecerni-pronajem-houseboatu-18079/2022-08-15;viewMode=7-days",
        },
      ],
    };
  },
  methods: {
    clickOnBtn(url) {
      open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-price-table {
  background: #f9f9f9;
  padding-top: 3rem;
  padding-bottom: 6rem;
  margin-top: 5rem;
}

.favorite-label {
  background: #c18a54;
  border-radius: 5rem;
  max-width: 8rem;
  width: 100%;
  position: absolute;
  top: -2rem;
  left: 50%;
  transform: translate(-50%);
  padding: 0.5rem;
  color: white;
  font-weight: bold;
  border: 3px solid white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.price-card {
  background: white;
  text-align: center;
  padding: 2rem;
  position: relative;

  &__favorite {
    background-image: url("https://vltavaparty.b-cdn.net/pamatky-pohledem-z-housebotu/6.jpg");
    background-size: cover;
    color: white;
  }

  &__price {
    font-size: 2.5rem;
    font-weight: bold;
  }

  &__text {
    font-size: 1rem;
    font-style: italic;
  }

  &__button {
    margin-top: 4rem;
  }
}

.content-wrapper {
  max-width: 1300px;
  margin: auto;
}
</style>