<template>
  <div class="gallery">
    <v-row>
      <v-col cols="2">
        <v-btn
          icon
          outlined
          color="primary"
          @click="$router.push({ name: 'Home' })"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h1 class="text-center mb-4">Galerie {{ galleryName }}</h1></v-col
      >
      <v-col cols="2"> </v-col>
    </v-row>
    <div class="page-gallery__grid">
      <v-row>
        <v-col
          cols="4"
          v-for="(photo, i) in photos"
          :key="i"
          class="page-gallery__grid-card"
        >
          <v-img
            :src="photo"
            @error="imgError(i)"
            @click="openImageDialog(photo)"
            aspect-ratio="1"
          />
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="showImageDetail" fullscreen>
      <div class="image-wrapper">
        <v-img
          max-height="90vh"
          contain
          :src="imgDetail"
          @click="showImageDetail = false"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showImageDetail: false,
      imgDetail: null,
      photos: [],
    };
  },
  computed: {
    galleryName() {
      const routeParam = this.$route.params.id;
      switch (routeParam) {
        case "interier-lodi":
          return "interiér lodi";
        case "pamatky-pohledem-z-housebotu":
          return "památky pohledem z housebotu";
        default:
          return "firemní akce a oslavy";
      }
    },
  },
  mounted() {
    this.fillPhotosArray(20);
  },
  methods: {
    fillPhotosArray(photosCount) {
      const routeParam = this.$route.params.id;
      for (let i = 1; i <= photosCount; i++) {
        this.photos.push(
          `https://vltavaparty.b-cdn.net/${routeParam}/${i}.jpg`
        );
      }
      return this.photos;
    },
    imgError(value) {
      const photosArray = [];
      const routeParam = this.$route.params.id;
      for (let i = 1; i <= value; i++) {
        photosArray.push(
          `https://vltavaparty.b-cdn.net/${routeParam}/${i}.jpg`
        );
      }
      this.photos = photosArray;
    },
    openImageDialog(imgSrc) {
      console.log(imgSrc);
      this.showImageDetail = true;
      this.imgDetail = imgSrc;
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  max-width: 1200px;
  margin: 5rem auto 6rem auto;
}

.page-gallery__grid {
  &-card {
    cursor: pointer;
    img {
      max-width: 100%;
      vertical-align: bottom;
    }
  }
}

.v-dialog {
  img {
    vertical-align: bottom;
  }

  .image-wrapper {
    display: flex;
    height: 100vh;
    align-items: center;
    background: rgba(31, 31, 31, 0.596);
  }
}
</style>