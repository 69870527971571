<template>
  <div class="page-photos">
    <div class="page-photos__title">{{ $t("pagePhotos.title") }}</div>
    <div class="page-photos__wrapper">
      <div
        class="page-photos__card"
        v-for="photoGallery in photoGalleries"
        :key="photoGallery.title"
        @click="$router.push({ path: `/galerie/${photoGallery.name}` })"
      >
        <div class="page-photos__card-title">
          {{ photoGallery.title }}
        </div>
        <v-img width="100%" :src="photoGallery.imgSrc" aspect-ratio="1">
        </v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      photoGalleries: [
        {
          title: this.$t("pagePhotos.cards.companyEvents"),
          imgSrc: `https://vltavaparty.b-cdn.net/firemni-akce/1.jpg`,
          name: "firemni-akce",
        },
        {
          title: this.$t("pagePhotos.cards.boatInterier"),
          imgSrc: "https://vltavaparty.b-cdn.net/interier-lodi/1.jpg",
          name: "interier-lodi",
        },
        {
          title: this.$t("pagePhotos.cards.sightSeen"),
          imgSrc:
            "https://vltavaparty.b-cdn.net/pamatky-pohledem-z-housebotu/2.jpg",
          name: "pamatky-pohledem-z-housebotu",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.page-photos {
  &__title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  &__card {
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 0 1rem;
    max-width: 350px;
    width: 100%;
    position: relative;
    cursor: pointer;

    &-title {
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.5rem;
      position: absolute;
      max-width: 350px;
      width: 100%;
      z-index: 1;
      color: white;
      bottom: 0;
      left: 0;
      padding: 1rem;
      background: rgb(56, 56, 56);
      background: linear-gradient(
        0deg,
        #383838 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}
</style>