<template>
  <div class="page-section">
    <div v-html="title" class="page-title"></div>
    <div v-html="body" class="page-body"></div>
  </div>
</template>

<script>
export default {
  name: "PageTextSection",
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.page-section {
  max-width: 70rem;
  margin: auto;
  .page-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .page-body {
    font-style: italic;
    color: gray;
    line-height: 1.7rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 300;
  }
}
</style>