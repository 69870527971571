<template>
  <div class="page-hero">
    <page-carousel :items="items" @clickOnBtn="clickOnBtn" />
  </div>
</template>

<script>
import PageCarousel from "./PageCarousel.vue";
export default {
  components: { PageCarousel },
  data() {
    return {
      items: [
        {
          title: this.$t("pageHero.privateParty.title"),
          text: this.$t("pageHero.privateParty.text"),
          src: "https://vltavaparty.b-cdn.net/zahlavi/1.jpg",
          actionText: this.$t("pageHero.privateParty.btn"),
        },
        {
          title: this.$t("pageHero.birthdayParty.title"),
          text: this.$t("pageHero.birthdayParty.text"),
          src: "https://vltavaparty.b-cdn.net/zahlavi/2.jpg",
          actionText: this.$t("pageHero.birthdayParty.btn"),
        },
        {
          title: this.$t("pageHero.companyCruise.title"),
          text: this.$t("pageHero.companyCruise.text"),
          src: "https://vltavaparty.b-cdn.net/zahlavi/3.jpg",
          actionText: this.$t("pageHero.companyCruise.btn"),
        },
      ],
    };
  },
  methods: {
    clickOnBtn() {
      open("https://vltavaparty.reenio.cz/cs/#/terms/viewMode");
    },
  },
};
</script>

<style lang="scss" scoped>
.page-hero {
  height: 100vh;
}
</style>
