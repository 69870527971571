<template>
  <div class="page-slide-group">
    <v-slide-group show-arrows>
      <v-slide-item v-for="(item, i) in items" :key="i">
        <v-card class="ma-6" height="200" width="320" flat>
          <v-row class="fill-height" align="center" justify="center">
            <slot name="item" v-bind="item" />
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
export default {
  name: "PageSlideGroup",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>