<template>
  <div class="home">
    <page-hero />
    <div class="content-wrapper">
      <page-photo-slider class="my-12" />
      <div v-for="textSection in textSections" :key="textSection.title">
        <page-section :title="textSection.title" :body="textSection.body" />
        <page-divider />
      </div>
      <page-section :title="map.title" :body="map.body" />
      <page-divider />
      <page-photos />
    </div>
    <page-price-table />
  </div>
</template>

<script>
// @ is an alias to /src
import PageDivider from "../components/PageDivider.vue";
import PageHero from "../components/PageHero.vue";
import PagePhotos from "../components/PagePhotos.vue";
import PagePhotoSlider from "../components/PagePhotoSlider.vue";
import PagePriceTable from "../components/PagePriceTable.vue";
import PageSection from "../components/PageSection.vue";

export default {
  name: "Home",
  components: {
    PageHero,
    PagePhotoSlider,
    PageSection,
    PageDivider,
    PagePhotos,
    PagePriceTable,
  },
  data() {
    return {
      textSections: [
        {
          title: this.$t("pageSection.boatEquipment.title"),
          body: this.$t("pageSection.boatEquipment.body"),
        },
        {
          title: this.$t("pageSection.refreshments.title"),
          body: this.$t("pageSection.refreshments.body"),
        },
        {
          title: this.$t("pageSection.route.title"),
          body: this.$t("pageSection.route.body"),
        },
      ],
      map: {
        title: this.$t("pageSection.orientationPoint.title"),
        body: this.$t("pageSection.orientationPoint.body"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  max-width: 1200px;
  margin: auto;
}
</style>
